.box-first {

    background-image: url("/img/assets/images/line.webp");
    background-size: cover;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
    padding: 60px;
}

.box-first .box-first__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
}

.box-first .box-first__title {
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 7px;
    color: #FFFFFF;
    text-transform: uppercase;
}

@media(max-width:1200px) {
    .box-first .box-first__box {
    flex-direction: column;
    }
}
.navbar-mobile {}

.navbar-mobile .navbar-mobile__wrapper {
    display: flex;
    justify-content: space-between;
    max-width: var(--max-width);
    margin: 15px auto;
    transition: padding 0.3s;
}

.navbar-mobile .navbar-mobile__space {
    justify-content: right;
    display: flex;
    gap: 20px;
    margin-right: auto;
    align-items: center;
}

.navbar-mobile .navbar-mobile__icon {
    display: flex;
    /* gap: 30px; */
    align-items: center;
    justify-content: center;
    margin-top: 45px;

}

.navbar-mobile .navbar-mobile__icon .navbar-mobile__ico {
    margin-right: 30px;
}

.navbar-mobile .navbar-mobile__icon .navbar-mobile__ico--is-last {

    margin: 0px;
}

.navbar-mobile .navbar-mobile__icon .navbar-mobile__ico:active,
.navbar-mobile .navbar-mobile__icon .navbar-mobile__ico:hover {
    transform: scale(1.1);
}

.navbar-mobile .navbar-mobile__menu {
    display: flex;
    align-items: center;
    /* gap: 40px; */
    margin-left: 50px;
}
.navbar-mobile .navbar-mobile__img{
    width: 60px;
    height: 60px;
}
.navbar-mobile .navbar-mobile__text {
    font-size: 14px;
    color: var(--color-white);
    text-transform: uppercase;
    margin-right: 40px;
}

.navbar-mobile .navbar-mobile__text__blue {
    font-size: 14px;
    color: var(--color-light);
    text-transform: uppercase;
    display: none;
    visibility: hidden;
}

.navbar-mobile .navbar-mobile-ico-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 540px;
    margin-left: 20px;
}

.navbar-mobile .navbar-mobile__icon .navbar-mobile__ico.navbar-mobile__ico--is-last {
    margin: 0px;
}

@media(max-width:1230px) {
    .navbar-mobile {
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    }

    .navbar-mobile .navbar-mobile__space {
    padding-right: 0;
    margin-right: 0;
    }

    .navbar-mobile .navbar-mobile__menu {
    /* gap: 20px; */
    /* margin-right: 20px; */
    margin-right: 0;
    }

    .navbar-mobile .navbar-mobile__text {
    margin-right: 20px;
    }
}

/* @media(max-width:1230px) {
    .navbar-mobile .navbar-mobile__menu {
    display: none;
    visibility: hidden;
    }

    .navbar-mobile .navbar-mobile-ico-nav {
    display: none;
    visibility: hidden;

    }

    .navbar-mobile .navbar-mobile__space {
    display: none;
    visibility: hidden;
    }

    .navbar-mobile .text__menu .navbar-mobile-ico--in--menu {
    visibility: visible;

    }

    .navbar-mobile .navbar-mobile__icon {
    justify-content: flex-start;
    margin-bottom: 50px;
    }
} */

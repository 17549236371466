.menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 50px 0;
    transform: translate(100%, 0);
    transition: transform 0.3s;
    background: linear-gradient(135deg, #323232, #020202);
    display: flex;
    flex-direction: column;
    z-index: 100;
}

/* #region Elements */
.menu .menu__action {
    cursor: pointer;
    text-transform: uppercase;
    color: #111;
    margin: 0 0 5px 0;
    padding: 10px 20px;
    padding-left: 100px;
}

.text__menu {
    color: black;
}
.text__menu .text__menu__categ h3{
    margin-bottom: 0;
    cursor: pointer;
}
.menu .text__menu__links {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
    background-color: #b1a04f;
    transition: padding 0.3s;
}
.menu .text__menu__link{
    margin-bottom: 25px;
    color: #020202;
    font-size: 14px;
    font-weight: 700;
    padding-left: 50px;
    letter-spacing: 5px;
    text-transform: uppercase;
	font-family: "Open Sans";
}
.menu .text__menu__link.--is-no-margin{
    margin: 0;
}

.menu .text__menu__links--is-open {
    max-height: 1000px;
    background-color: #b1a04f;
    margin-top: 20px;
    padding: 20px 0;
}

.text__menu .text__menu-blue {
    color: #b1a04f;
    margin-bottom: 30px;
}

.text__menu h3 {
    font-size: 14px;
    color: var(--color-white);
    text-transform: uppercase;
    margin-top: 30px;
    padding-left: 50px;
}

.ico__menu {
    display: flex;
    justify-self: right;
    bottom: 30px;
    left: 130px;
    margin: 20px;
    justify-content: right;
    position: relative;

}

.menu .menu__social-menu {
    position: relative;
    left: 100px;
    right: 100px;
    padding: 20px;
    display: grid;
    justify-content: right;
}

.menu .menu__social-menu a {
    margin-bottom: 40px;
}

/* #endregion */

/* #region Modifiers */
.menu.menu--is-open {
    transform: translate(0, 0);
    background-color: var(--color-primary);
}

.menu .menu__action.menu__action--is-close {
    padding-right: 130px;
    padding-top: 30px;
    margin: 0 0 20px 0;
}

.container__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/* #endregion */
@media(min-width:1316px) {
    .container__menu {
    visibility: hidden;
    display: none;
    }
}

@media(min-width:1316px) {
    .container__menu {
    visibility: visible;

    }

}

@media(max-width:465px) {
    .menu .menu__action.menu__action--is-close {
    padding-right: 135px;
    }
}

@media(max-width:400px) {

    .menu .menu__action.menu__action--is-close {
    padding-right: 145px;
    }
}
.header-2 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    transition: background-color 0.3s;
}
.header-2.header--is-fixed {
    background: linear-gradient(135deg,#323232,#020202);
}
.form-produto {
    padding: var(--max-width-padding);
    max-width: 1440px;
    max-width: var(--max-width);
    display: grid;
    grid-template-columns: 1fr minmax(auto, 590px);
    gap: 80px;
    margin: 50px auto 100px;
}

.form-produto .form-produto__direction {
    display: flex;
    margin-bottom: 30px;
}

img.form-produto__img {
    width: 100%;
    border-radius: 10px;
}

.form-produto .form-produto__gallery {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 20px;
}
.form-produto .form-produto__content__box {
    display: grid;
    grid-template-columns: 1fr minmax(auto,615px);
    gap: 20px;
}
.form-produto .form-produto__content__box__img {
    margin-bottom: 10px;
    border-radius: 5px;

    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.form-produto .form-produto__content__box__img:last-child {
    margin: 0;
}

.form-produto .form-produto__content__box__img::before {
    content: " ";
    display: block;
    padding-top: 87%;
}

.form-produto .form-produto__content__box__img > img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    will-change: transform;
    transition: transform 0.3s;
}

.form-produto .form-produto__content>div {
    position: sticky;
    top: 80px;
}

.form-produto .form-produto__text-blue {
    color: var(--color-light);
}
@media (max-width:1200px) {
    .form-produto {
    grid-template-columns: 1fr;
    gap: 0;
    }
    .form-produto .form-produto__content__box {
    grid-template-columns: 1fr;
    }
    .form-produto .form-produto__gallery {
    grid-template-columns: 1fr;
    }
    .form-produto .form-produto__gallery > div:nth-child(1) {
    order: 2;
    }
}
.grid-sellers {
    max-width: var(--max-width);
    margin: 0 auto 0px auto;
    width: 95%;
}

/* #region Elements */


.grid-sellers .grid-sellers__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin:  0 auto;
    margin-bottom: 75px;
}

.grid-sellers .grid-sellers__item-img {
    position: relative;
    overflow: hidden;
    text-align: center;
}
.grid-sellers__title{
    text-align: center;
    color: black;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 50px;
    padding-top: 50px;
}
.grid-sellers__title.--is-white{
    color: white;
}
.grid-sellers .grid-sellers__back-img {}

/* .grid-sellers .grid-sellers__item-img::before {
    content: " ";
    display: block;
    padding-top: 20%; */
/*padding-top: calc(100% * (9 / 16));*/
/* inves de tamanho, aqui controlamos a proporção da imagem em relação há largura, ex: 9/16 */
/* quando quero um tamanho especifico, acerto como fiz agora para descobrir a percentagem adequada */

.grid-sellers .grid-sellers__item-img>img {
    /* display: block;
    position: absolute; */
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    z-index: 1;
    will-change: transform;
    transition: transform 0.3s;
}

.grid-sellers .grid-sellers__name {
    color: #6B6B6B;
    font-size: 15px;
    letter-spacing: 0.50px;
}
.grid-sellers .grid-sellers__list h3{
    margin-bottom: 0;
}
.grid-sellers .grid-sellers__list h2{
    margin-top: 8px;
}
.grid-sellers .grid-sellers__price  {
    color: black;
    letter-spacing: 4px;
}
.grid-sellers .grid-sellers__price.--is-white{
    color: white;
}
/* #endregion */

/* #region Modifiers */
.grid-sellers .grid-sellers--is-mtb-500 {
    margin-bottom: 100px;
}

.grid-sellers .grid-sellers--is-mtb-100 {
    margin-top: 20px;
    margin-bottom: 100px;
}

.grid-sellers .grid-sellers__item:active .grid-sellers__item-img>img,
.grid-sellers .grid-sellers__item:hover .grid-sellers__item-img>img {
    transform: scale(1.1);
}

/* #endregion */

@media (max-width: 1090px) {

    .grid-sellers{
    width: 100%;
    }
    .grid-sellers .grid-sellers__list {
    grid-template-columns: repeat(3, 1fr);
    width: 90%;
    }
}

@media (max-width:865px) {
    .grid-sellers .grid-sellers__list {
    grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .grid-sellers .grid-sellers__list {
    grid-template-columns: 1fr;

    }

    .grid-sellers .grid-sellers__border {}
}
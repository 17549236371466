.gallery-2 {
    width: 95vw;
    height: 95vh;
    margin: 0 auto;
}

/* #region Elements */
.gallery-2 .gallery-2__full {
    /* cursor: zoom-in; */
    width: 100%;
    height: 100%;
}

.gallery-2 .gallery-2__swipper {
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-img);
}

.gallery-2 .gallery-2__full img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    width: 60%;
}

.gallery-2 .gallery-2__button-prev,
.gallery-2 .gallery-2__button-next {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background-image: url(/img/assets/icons/arrow.svg);
    background-size: 12px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 999;

}

.gallery-2 .gallery-2__button-next {
    right: 20px;
    transform: rotate(360deg);
}

.gallery-2 .gallery-2__button-prev {
    left: 20px;
    transform: rotate(180deg);
}

.gallery-2__pagination {
    --swiper-pagination-color: var(--color-primary);
    position: absolute;
    bottom: 20px;
    width: 100%;
    height: 40px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* #endregion */

/* #region Modifiers */
/* #endregion */

@media (max-width: 1023px) {}

@media (max-width: 767px) {
    .gallery-2 {
    width: 100%;
    height: 100%;
    }
}
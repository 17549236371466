/* CSS Document */

#no-margins1{
	margin-top: 0px;
	margin-bottom: 0px;
}

#no-margins2{
	margin-top: 0px;
	margin-bottom: 0px;
}
.container {
	display: flex;
	max-width: 1440px;
	margin: auto;
	width: 95%;
	margin-top: 20px;
	margin-bottom: 20px;
	column-gap: 25px;
}

.containercenter {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	max-width: 1440px;
	margin: auto;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
}

.fullcontainer {
	display: flex;
	justify-content: center;
	margin: auto;
	width: 100%;
	margin-top: 30px;
	margin-bottom: 30px;	
}

.textcontainer {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: center;
	width: 50%;
	padding: 0 0px;
	
}

.textcontainercenter {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 50%;
	padding: 0 0px;
	
}

.textcenter {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 0 0px;
	text-align: center;
	
}

.imgcontainer {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	width: 50%;
	
}

.imgcontainerfull {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	
}

.textcontainer-2 {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 20%;
	padding: 0 0px;
	
}
.textcontainer-8 {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: center;
	width: 80%;
	padding: 0 0px;
	
}

.textcontainer-10 {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	padding: 0 0px;
	
}






/* CSS Font Document */

@font-face {
  font-family: "Open Sans";
	src: url("/fonts/OpenSans-VariableFont_wdth,wght.ttf") format("truetype");
}

/* Text Style */

h1 {
	font-family: "Open Sans";
	font-size: 34px;
	font-weight: 700;
	letter-spacing: 10px;
	color: #f2f2f2;
}

h2 {
	font-family: "Open Sans";
	font-size: 20px;
	font-weight: 700;
	letter-spacing: 6px;
	color: #f2f2f2;
	text-transform: uppercase;
}

h3 {
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 100;
	letter-spacing: 4px;
	color: #f2f2f2;
}

h4 {
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 100;
	letter-spacing: 4px;
	color: #f2f2f2;
	opacity: 0.7;
}

p {
	font-family: "Open Sans";
	font-size: 15px;
	font-weight: 400;
	color: #f2f2f2;
	line-height: 30px;
}

a {
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 4px;
	color: #f2f2f2;
	text-decoration: none;
	text-transform: uppercase;
}

a:hover{
	opacity: 0.7;
}


/* CSS Document */

.responsive {
	width: 100%;
	height: auto;
}

#textdesktop {
	padding: 0px 0px 0px 70px;
	/*padding: 0px 100px 0px 250px; */
}

@media screen and (max-width: 865px) {

	.bannerhome {
		height: 350px;
		margin: 0px;
	}

	.bannertime {
		display: none;
	}

	#emptydesktop {
		display: none;
	}

	#textdesktop {
		display: none;
	}

	.textcontainer {
		width: 95%;
	}

	.container {
		flex-flow: column;
		gap: 30px;
		width: 90%;
	}


	.imgcontainer {
		width: 100%;
		padding: 0px;

	}

	.fullcontainer {
		flex-flow: column;
		width: 100%;
	}
	.adfbackground {
		padding-top: 50%;
	}

	.acrbackground {
		padding-top: 50%;
	}

}

@media screen and (min-width: 768px) and (max-width: 1024px) {

	.fullcontainer {
		width: 100%;
	}

	.textcontainercenter {
		width: 95%;
		padding: 0 20px;
	}

}

@media screen and (min-width: 768px) {

	#textmobile {
		display: none;
	}
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
	.bannertime {
		height: 100%;
	}
}
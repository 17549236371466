/* CSS Document */
.bannerhome {
	display: flex;
	background-image: url("/img/assets/images/banner-home.webp");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	overflow: visible;
	height: 750px;
}
.bannertime {
	display: flex;
	background-image: url("/img/assets/images/banner-time.webp");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	overflow: visible;
	height: 750px;
}
.adfbackground {
	background-image: url("/img/assets/images/adf-07.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	padding-top: 30%;
	width: 100%;
	display: flex;
	justify-content: center;	
	align-items: flex-end;
	padding-bottom: 50px;
}
.acrbackground {
	background-image: url("/img/assets/images/alcapone-10.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	padding-top: 30%;
	width: 100%;
	display: flex;
	justify-content: center;	
	align-items: flex-end;
	padding-bottom: 50px;
}
.aboutusbanner {
	background-image:url("/img/assets/images/baner-about.webp");
	background-position: center;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.single-seaters-banner {
	background-image:url("/img/assets/images/baner-single-seaters.webp");
	background-position: center;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.partnersbanner {
	background-image:url("/img/assets/images/partnersbanner.webp");
	background-position: center;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.caramulo2021banner {
	background-image:url("/img/assets/images/Caramulobanner.webp");
	background-position: right;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.caramulo2022banner {
	background-image:url("/img/assets/images/caramulo2022banner.webp");
	background-position: center;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}

.algarve2022banner {
	background-image:url("/img/assets/images/algarve2022banner.webp");
	background-position: center;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.estoril2021banner {
	background-image:url("/img/assets/images/estorilbanner.webp");
	background-position: right;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}

.banner-2022-race-1-estoril {
	background-image:url("/img/assets/images/banner-2022-race-1-estoril.webp");
	background-position: right;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.banner-2022-race-3-estoril {
	background-image:url("/img/assets/images/banner-2022-race-3-estoril.webp");
	background-position: right;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.banner-2022-race-4-estoril {
	background-image:url("/img/assets/images/banner-2022-race-4-estoril.webp");
	background-position: right;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.adfacreventbanner {
	background-image:url("/img/assets/images/adf-acr-event-banner.webp");
	background-position: right;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.cabodomundobanner {
	background-image:url("/img/assets/images/cabo-do-mundo-banner.webp");
	background-position: right;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.estoril2021corrida2 {
	background-image:url("/img/assets/images/estorilbanner-corrida2.webp");
	background-position: center;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.singleseaterbanner {
	background-image:url("/img/assets/images/singlebanner.webp");
	background-position: center;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.falperrabanner {
	background-image:url("/img/assets/images/falperrabanner.webp");
	background-position: center;
	background-size: cover;
	height: 500px;
	max-width: 1440px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
}
.adf-about-background {
	background-image: url("/img/assets/images/adf-07-about-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	width: 100%;
	display: flex;
	justify-content: center;	
	align-items: flex-end;
}
.acr-about-background {
	background-image: url("/img/assets/images/alcapone-10-about-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	width: 100%;
	display: flex;
	justify-content: center;	
	align-items: flex-end;
}
.youtubevideo {
	width: 100%;
	height: 100%;
	
}
.mapacontactos {
	max-width: 1440px;
	width: 100%;
	height: 500px;
}
@media screen and (max-width: 1024px) {
	.youtubevideo { 
		padding-bottom: 30%;
	}
}


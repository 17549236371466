/* CSS Document */
li {
	list-style: none;
}

.header {
	margin: 15px 0;
	display: flex;
	align-items: center;
	height: 100px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
}

.navbar {
	display: flex;
	justify-content: center;
}

.nav-menu {
	display: flex;
	align-items: center;
}
.nav-menu li:last-child{
	margin: 0;
}
.nav-menu .nav-menu__menu.nav-menu__menu--has-submenu a {
	position: relative;

}

.nav-menu .nav-menu__menu.nav-menu__menu--has-submenu>a:after {
	display: block;
	content: ' ';
	height: 5px;
	transition: width 0.3s;
	width: 0;
	background-color: #B1A04F;
	position: absolute;
	top: 100%;
	left: 0;
	margin-top: 1.5px;
}

.nav-menu .nav-menu__menu.nav-menu__menu--has-submenu:hover>a:after {

	width: 100%;
}


.nav-menu li {
	height: 115px;
	display: flex;
	align-items: center;
	margin-right: 60px;

}

.nav-menu ul ul {
	z-index: 100;
}

.nav-menu .nav-menu__sub-menu-wrapper {
	max-width: 1120px;
	margin: 0 auto;
	width: 100%;
}

.nav-menu__menu .nav-menu__sub-menu a {
	height: 30px;
	margin-right: 30px;
	color: #070909;
	font-weight: 700;
}

.nav-menu .nav-menu__sub-menu {
	height: 30px;
	margin-right: 30px;
	color: black;
	font-family: "Open Sans";
	font-weight: 700;
}

.nav-menu .navbar__ico.--is-hidden {
	display: none;
}

.nav-menu__menu .nav-menu__menu__box {
	display: flex;
	margin: 0 auto;

}

.nav-menu .nav-menu__menu__box {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s;
	background-color: #b1a04f;
	transition: padding 0.3s;
	position: absolute;
	top: 110px;
	right: 0;
	left: 0;
}

.nav-menu .nav-menu__menu__box--is-open {
	max-height: 50px;
	background-color: #b1a04f;
	padding: 20px 0px;
	display: flex;
}

ul {
	margin: 0;
}

.navbar .navbar__icon {
	display: flex;
	/* gap: 30px; */
	align-items: center;
	justify-content: center;
	margin-right: 60px;
}


.navbar .navbar__icon.--is-hidden {
	visibility: hidden;
}

.navbar .navbar__icon .navbar__ico {
	margin-right: 30px;
}

.navbar .navbar__icon .navbar__ico--is-last {

	margin: 0px;
}

.link__about__text {
	max-height: 0;
	overflow: hidden;
	transition: max-height .3s;
	position: relative;
	width: 100%;
	left: 0;
	background-color: #b1a04f;
	z-index: 10;
	padding: 0 20px;
}

.link__about__text--is-open {
	max-height: 200px;
	width: 100%;
	padding: 10px;
}

.nav-about__box .nav-about__text {
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 3.2px;
	color: #070909;
	text-transform: uppercase;
	margin-right: 30px;
	font-family: "Open Sans";
}

.nav-about .nav-about__text.--is-no-margin {
	margin: 0;
}

ul {
	padding: 0px;
}


.topnav {
	overflow: hidden;
	background-color: transparent;
	position: relative;
}

.topnav #myLinks {
	display: none;
}

.topnav a {
	color: white;
	text-decoration: none;
	font-size: 17px;
	display: block;
	line-height: 70px;
}

.topnav a.icon {
	background-color: transparent;
	display: flex;
	flex-flow: row;
	justify-content: flex-end;
	right: 0;
	top: 0;
}

.navbar {
	width: 100%;
	padding: 0;
	margin: 0;
	position: relative;
}

nav ul {
	list-style: none;
}


nav ul li {
	display: inline-block;

}


nav ul li a {
	display: block;
	text-decoration: none;
	float: left;
	cursor: pointer;
}

/* nav ul li a.--is-no-height {
    height: 60px;
} */
nav ul li:hover {}


nav ul li:hover>a {
	color: #FFFFFF;
}

.navbar .nav__text a {
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 3.2px;
	color: #070909;
	text-transform: uppercase;
}

.navbar .nav__text-store {
	color: #B1A04F;
}

nav ul li:hover>ul {
	display: block;
	border-bottom: 3px solid #B1A04F;
	transition: 0.3s;

}

nav ul ul {
	background: #B1A04F;
	padding: 0;
	text-align: left;
	display: none;
	width: 100%;
	position: absolute;
	top: 112px;
	left: 0;
	padding: 22px 0 0 0px;
	z-index: 100;
}

@media screen and (min-width: 0px) and (max-width: 1230px) {
	#headerdesktop {
		display: none;
	}

	.header {
		width: 90%;
		align-items: flex-start;
	}

	.topnav a.icon {
		position: absolute;
	}

	.topnav #myLinks {
		margin-top: 30px;
	}
}

@media screen and (min-width: 1230px) {
	#headermobile {
		display: none;
	}

	.navbar-mobile {
		display: none;
		visibility: hidden;
	}
}
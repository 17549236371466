/* CSS Document */
.btn {
	border: 2px solid #f2f2f2;
	background-color: transparent;
	color: #f2f2f2;
	padding: 14px 28px;
	cursor: pointer;
	font-weight: bold;
	letter-spacing: 8px;

}

.box__button {
	margin-top: 50px;
	text-align: center;
}
.box__button-2{
	margin-top: 40px;
}
.btn.--is-header {
	color: black;
	border: 2px solid;
}
.btn.--is-gold{
	color: #B1A04F;
	border: 2px solid #B1A04F;
}
.btn.--is-gold:hover{
	background-color: #B1A04F;
	color: #f2f2f2;
}
.btn.--is-header:hover {
	background-color: black;
	color: #fff;
}
.btn.btn--is-full{
	width: 100%;
	font-size: 20px;
	letter-spacing: 4px;
	text-transform: uppercase;
}


.btn:hover {
	background-color: #fff;
	color: #020202;
	font-weight: bold;
	opacity: 1;
}
.box-energy {
    text-align: center;
    max-width: 1440px;
    margin: 0 auto;
    width: 95%;
}


.box-energy .box-energy__title {
    text-align: center;
    letter-spacing: 17px;
    color: #F2F2F2;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.box-energy .box-energy__img>img {
    width: 100%;

}

.box-energy .box-energy__grid-2__title {
    letter-spacing: 1.7px;
    text-transform: uppercase;
}

.box-energy .box-energy__grid-2__title.--is-gold {
    color: #B1A04F;
}

.box-energy .box-energy__grid-2__title.--is-silver {
    color: black;
}

.box-energy__grid-2__subtitle {
    font-size: 18px;
    letter-spacing: 0.9px;
    color: #6B6B6B;
    text-transform: uppercase;
}

.box-energy .box-energy__grid__img>img {
    width: 100%;
}

.box-energy .box-energy__grid {
    padding: 0 var(--max-width-padding);
    max-width: var(--max-width);
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
    justify-content: center;

}

.box-energy .box-energy__grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    text-align: left;
}

.box-energy .box-energy__grid-2.--is-color-one {
    background-color: #D8CFB1;
}

.box-energy .box-energy__grid-2.--is-color-two {
    background-color: #F2F2F2;
}

@media (max-width:1090px) {

    .box-energy {
    width: 90%;
    }

    .box-energy .box-energy__grid {
    grid-template-columns: 1fr;
    }

}


@media (max-width:600px) {
    .box-energy .box-energy__grid-2 {
    grid-template-columns: 1fr;
    padding-bottom: 45px;

    }

    .box-energy .box-energy__col {
    text-align: center;
    }

}
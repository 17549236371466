.product-colors {
    display: flex;
    position: relative;
    justify-content: center;
    flex-wrap: wrap;
}

/* #region Elements */
.product-colors__item {
    margin: 0 9px 9px 0;
    border-radius: 100%;
}

.product-colors__item:last-child {
    margin: 0 0 8px 0;
}

.product-colors__error {
    text-transform: uppercase;
    font-family: var(--font-normal), sans-serif;
    font-size: 18px;
    color: #F44336;
}

.product-colors__item label {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    position: relative;
    overflow: hidden;
    border: 1px solid white;
    color: white;
    height: 26px;
    width: 26px;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    font-family: "Verdana";
}

.product-colors__item input {
    display: none;
}

.product-colors__item input:disabled+label {
    cursor: default;
    opacity: 0.75;
    background-color: #ddd;
    border-color: #ddd;
}

/* .product-colors__item input:disabled + label::after {
    display: block;
    content: ' ';
    width: 200%;
    height: 3px;
    background-color: #E6E6E6;
    position: absolute;
    top: calc(50% - 1px);
    transform: rotate(-45deg);
} */
.product-colors .product-colors__color-element {
    border: 1px solid #C9C9C9;
    border-radius: 100%;
    overflow: hidden;
    width: 24px;
    height: 24px;
}

.product-colors .product-colors__color-element-inside {
    width: 24px;
    height: 24px;
}

/* #endregion */

/* #region Modifiers */
.product-colors__item input:checked+label,
.product-colors__item input:checked+label .product-colors__item-check {
    opacity: 1;
}

.product-colors__item input:checked+label {
    color: black;
    background-color: white;
    border-color: white;
}

.product-colors .product-colors__item.product-colors__item--is-active .product-colors__color-element {
    border: 1px solid var(--color-primary);
}

/* #endregion */
.product-options {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;
    justify-items: flex-start;
    gap: 20px;
}

/* #region Elements */
.product-options .product-options__info {}

.product-options .product-options__title-collection {
    font-family: "Open Sans";
    color: #6B6B6B;
    letter-spacing: 0.50px;
    font-size: 12px;
    margin: 0 0 3px 0;
}

.product-options h2 {
    text-transform: unset;
}

.product-options .product-options__title {
    text-transform: uppercase;
    font-family: "Open Sans";
    color: white;
    font-size: 24px;
    letter-spacing: 0.50px;
    margin: 0 0 12px 0;
}

.product-options .product-options__price {
    margin: 0 0 23px 0;
    font-family: "Open Sans";
    color: white;
    font-weight: 300;
    letter-spacing: 0.50px;
    font-size: 20px;
}

.product-options .product-options__price>span {
    text-decoration: line-through;
    font-size: 12px;
    font-weight: 300;
    color: rgba(0, 0, 0, .5);
    margin: 0 0 0 5px;
}

.product-options .product-options__price-label {
    font-size: 11px;
    color: #fff;
    font-family: "Open Sans";
    background-color: var(--color-primary);
    padding: 5px 10px 5px 10px;
    text-transform: uppercase;
    display: inline-block;
    margin: 0 0 10px 0;
}

.product-options .product-options__info-content {
    line-height: 1.5;
}

.product-options .product-options__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}

.product-options .product-options__label {
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: 0.50px;
    text-transform: uppercase;
    color: white;
    margin: 0 0 12px 0;
}

.product-options .product-options__link {
    text-decoration: none;
    color: #6B6B6B;
    display: inline-block;
    cursor: pointer;
    text-transform: none;
    margin: 0 0 0 10px;
    letter-spacing: 0.50px;
    font-size: 11px;
    font-weight: 300;
}

.product-options .product-options__content {
    font-family: "Open Sans";
    font-size: 18px;
    text-transform: uppercase;
    color: white;
}

.product-options .product-options__options {}

.product-options .product-options__options select {
    font-family: "Open Sans";
    font-size: 12px;
    text-transform: uppercase;
    border: 0;
    background-color: black;
    color: white;
    padding: 4px 20px 4px 20px;
    border: 2px solid #E6E6E6;
}

.product-options .product-options__options select option:disabled {
    color: #ccc;
}

.product-options .product-options__action {
    border: none;
    background-color: var(--color-black);
    height: 40px;
    width: 100%;
    color: #fff;
    font-family: "Open Sans";
    font-size: 11px;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0 0 10px 0;
    padding: 4px 80px 0 80px;
}

.product-options .product-options__action:disabled {
    opacity: 0.5;
}

.product-options .product-options__action:last-child {
    margin: 0;
}

.product-options .product-options__error {
    text-transform: uppercase;
    font-family: "Open Sans";
    font-size: 11px;
    color: #F44336;
}

/* #endregion */

/* #region Modifiers */
.product-options .product-options__action.product-options__action--is-invert {
    border: 1px solid var(--color-black);
    color: var(--color-black);
    background-color: transparent;
}

.product-options.product-options--is-xl {
    grid-template-columns: minmax(250px, 1fr) 200px 200px;
    gap: 60px;
}

/* #endregion */

/* @media (max-width: 1299px) {
    .product-options {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-content: center;
    }
    .product-options__info {
    grid-column: 1 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    .product-options__box:last-child {
    grid-column: 1 / 4;
    }
} */
@media (max-width: 1023px) {
    .product-options {
    justify-items: center;
    }

    .product-options__box.product-options__box--is-inline,
    .product-options__subbox.product-options__subbox--is-inline {
    align-items: center;
    }

    .product-options .product-options__title {
    text-align: center;
    }
}

@media (max-width: 767px) {
    .product-options {
    grid-template-columns: 1fr;
    }

    .product-options__info {
    grid-column: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    }

    .product-options__box:last-child {
    grid-column: 1;
    }

    .product-options__box.product-options__box--is-inline,
    .product-options__subbox.product-options__subbox--is-inline {
    display: flex;
    flex-direction: column;
    }

    .product-options .product-options__label {
    margin: 0 0 15px 0;
    }

    .product-options__subbox.product-options__subbox--is-inline {
    justify-content: space-between;
    width: 100%;
    }

    .product-options .product-options__price {
    text-align: center;
    }
}
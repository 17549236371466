.background-header {
    background: transparent linear-gradient(115deg, #FDFDFD 0%, #ECECEC 100%) 0% 0%;
    padding-bottom: 100px;
}

.background-header--is-black {
    background: rgb(57, 63, 65);
    background: linear-gradient(135deg, rgba(50, 50, 50, 1) 0%, rgba(2, 2, 2, 1) 100%);
    background-attachment: fixed;
    margin-top: 50px;
    padding-bottom: 100px;
}

.background-header .swiper-pagination-bullet {
    background-color: black;
    opacity: 1;
    width: 30px;
    height: 5px;
    border-radius: 0;
}

.background-header .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #B1A04F;
}

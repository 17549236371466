.product-images {
    padding: 0;
    max-width: 1356px;
    margin: 0 auto 0 auto;
    position: relative;
    width: 100%;
}

/* #region Elements */
.product-images .product-images__full {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.product-images .product-images__full::after {
    content: ' ';
    display: block;
    padding-top: 80%;
}

.product-images .product-images__group {
    grid-template-columns: repeat(4, 1fr);
    gap: 13px;
    display: none;
    margin-top: 13px;
}

.product-images .product-images__item {
    position: relative;
    background-color: var(--color-black);
    width: 100%;
    cursor: pointer;
}

.product-images .product-images__item::before {
    content: ' ';
    display: block;
    padding-top: 89%;
}

.product-images .product-images__item img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
}

/* #endregion */

/* #region Modifiers */
.product-images .product-images__group.product-images__group--is-active {
    display: grid;
}

.product-images.product-images--is-full {
    /* grid-column: 1 / 3; */
}

/* #endregion */
@media (max-width: 1023px) {
    .product-images.product-images--is-full {
    grid-column: unset;
    }
}

@media (max-width: 800px) {}

@media (max-width: 500px) {
    .product-images .product-images__group {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
}
/* CSS Document */

.grid-5-elements {
	display: grid;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	grid-template-rows: auto;
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 25px;
	width: 100%;
}

.grid-4-elements {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	grid-template-rows: auto;
	column-gap: 20px;
	row-gap: 40px;
	margin-bottom: 25px;
	width: 100%;
}


.grid-3-elements {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-template-rows: auto;
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 25px;
	margin: 0px auto;
}

.grid-2-elements {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-template-rows: auto;
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 25px;
}

.colum-2-elements {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	grid-template-rows: auto;
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 25px;
}


@media screen and (max-width: 768px) {
	.grid-2-elements {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
	
	.grid-3-elements {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
	
	.grid-4-elements {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-gap: 50px;
	}
	.grid-5-elements {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-gap: 50px;
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.grid-2-elements {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
}
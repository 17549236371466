/* CSS Document */
/*
.footer {
	max-width: 1440px;
	margin: 15px auto;
	width: 95%;

}

.containerfooter {
	display: flex;
	justify-content: space-between;
	align-content: center;

}

.colfooter {
	display: flex;
	align-items: center;
	/*gap: 30px;

}

.colfooter__img {
	margin-right: 30px;
}

.colicons {
	display: flex;
	align-items: center;
	/* gap: 20px;

}

.colicons .colicons__ico {
	margin-right: 20px;
}

.colicons .colicons__ico.--is-no-margin {
	margin: 0;
}

.infofooter {
	display: flex;
	align-items: center;
	/*gap: 20px; 

}

.infofooter .infofooter__text {
	color: #f2f2f2;
	letter-spacing: 4px;
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 400;
}

.infofooter .infofooter__ico {
	height: 15px;
	margin-right: 15px;
}

.infofooter .infofooter__text.--is-margin {
	margin-right: 20px;
}

#footer {
	letter-spacing: 4px;
	font-size: 12px;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.footer__terms {
	display: flex;
	color: white;
	font-family: "Open Sans";
	align-items: center;
	font-size: 10px;
	letter-spacing: 5px;
}

.footer__terms-mobile {
	display: flex;
	color: white;
	font-family: "Open Sans";
	align-items: center;
	font-size: 10px;
	letter-spacing: 5px;
}

.barra {
	height: 1px;
	width: 95%;
	background-color: white;
	max-width: 1440px;
	margin: auto;
	margin-bottom: 10px;

}

@media(max-width:1090px) {
	.barra {
		width: 90%;
	}

}

@media (max-width: 1345px) {

	.footer {
		flex-flow: column;
		justify-content: center;
		align-content: center;
		gap: 0px;
		margin-bottom: 30px;
	}

	.footer .footer__terms {
		display: none;
		visibility: hidden;
	}

	.footer__terms-mobile {
		flex-direction: column;
	    margin: 10px auto;
	}

	.footer__terms-mobile .footer__terms-mobile__text {
		margin-bottom: 10px;

	}

	.colfooter {
		flex-flow: column;
		gap: 0px;
	}

	.colfooter .colfooter__img.--is-no-margin {
		margin: 0;
	}

	.colicons {
		justify-content: center;

	}

	.infofooter {
		flex-flow: column;
		gap: 0px;
		margin: 15px 0px;

	}

	.infofooter .infofooter__ico {
		margin: 0;
	}
	.infofooter .infofooter__text.--is-margin{
		margin: 0;
	}

	.containerfooter {
		flex-flow: column;
	}

	#footer {
		align-self: center;
	}
}

@media(min-width: 1345px) {
	.footer__terms-mobile {
		display: none;
		visibility: hidden;
	}
}
*/
.footer {
	padding-bottom: 50px;
	overflow: hidden;
}

.barra {
	height: 1px;
	width: 95%;
	background-color: white;
	max-width: 1440px;
	margin: auto;
	margin-bottom: 10px;
	margin-top: 50px;

}

.footer .footer__content {
	display: grid;
	max-width: var(--max-width);
	width: 95%;
	margin: 0 auto;

}

.footer .footer__section {
	padding-top: 30px;
	text-align: right;
}

.footer .footer__terms-box {
	display: flex;
	margin: 0 auto;
	justify-content: center;
	margin-top: 30px;
}

.footer .footer__terms-box__text {
	font-size: 12px;
	font-family: "Open Sans";
	letter-spacing: 0.170px;
	color: white;
	text-transform: uppercase;
	margin-right: 5px;
}

.footer .footer__terms-box__link {
	font-size: 12px;
	font-family: "Open Sans";
	letter-spacing: 0.170px;
	color: white;
	font-weight: 700;
	text-transform: uppercase;
}

.footer .footer__section .footer__projecto-img {
	margin-top: 30px;
}

.footer .footer__section .footer__logo {
	width: 60px;
	height: 60px;
	cursor: pointer;
}

.footer .footer__text {
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 0.150px;
	color: white;
	font-family: "Open Sans";
}

.footer .footer__inner-content.footer__inner-content--is-4-columns {
	display: flex;
	justify-content: space-between;
	text-align: left;
}

.footer .footer__box {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.footer .footer__box h5 {
	margin: 0;
}

.footer .footer__img {
	width: 15px;
	margin-right: 10px;
	margin-bottom: 10px;
}

.footer .footer__img.footer__img--is-last {
	margin-bottom: 0px;
}

.footer .footer__icons {
	display: flex;
	align-items: center;
	margin-top: 10px;
	/* gap: 20px; */
}

.footer .footer__title {
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.150px;
	font-family: "Open Sans";
	color: white;
	text-transform: uppercase;
}

.footer .footer__icons .footer__img-2 {
	margin-right: 20px;
	height: 15px;
	width: 15px;
	align-items: center;
}

.footer .footer__icons .footer__img-2.footer__img-2---is-last {
	margin: 0px;
}

.footer .footer__section .footer__img-2:active,
.footer .footer__section .footer__img-2:hover {
	transform: scale(1.1);
}

.footer .footer__box .footer__in-box {
	display: flex;
	/* gap: 10px; */
	flex-direction: column;
	gap: 15px;
}

.footer .footer__box .footer__in-box>div {
	display: flex;
	/* gap: 10px; */
}



.footer .footer__terms {
	padding: 0 var(--max-width-padding);
	max-width: var(--max-width);
	margin: 100px auto 50px auto;
	background-color: var(--color-white);
	text-align: center;
	line-height: 1.4;
}

.footer .footer__terms .footer__text {
	color: black;

	line-height: 1.5;
	font-size: 13px;
	font-weight: 700;
}

.footer .footer__terms .footer__text_2 {
	color: black;
	font-size: 13px;
	font-weight: 300;
}

@media(max-width:1090px) {
	.barra {
		width: 90%;
	}

}

@media (max-width: 1024px) {


	.footer .footer__section {
		text-align: center;
	}

	.footer .footer__inner-content.footer__inner-content--is-4-columns {
		gap: 40px;
	}
}

@media(max-width: 1200px) {
	.footer .footer__inner-content.footer__inner-content--is-4-columns {
		grid-template-columns: 1fr 1fr;
		text-align: center;
		display: grid;
		gap: 40px;
	}

	.footer .footer__icons {
		align-self: center;
	}

	.footer .footer__in-box {
		align-items: center;
	}
.language{
	justify-content: center;
}
}

@media(max-width: 600px) {
	.footer .footer__inner-content.footer__inner-content--is-4-columns {
		grid-template-columns: 1fr;
		display: grid;
	}

	.footer .footer__section .footer__projecto-img {
		width: 300px;
	}

	.footer .footer__title,
	.footer .footer__link {
		text-align: center;
	}

	.footer .footer__box {
		align-items: center;
	}

	.footer .footer__terms-box {
		flex-direction: column;
		align-items: center;
	}

	.footer .footer__terms-box__text {
		margin-right: 3%;
		margin-bottom: 10px;
		margin-left: 3%;
	}

}
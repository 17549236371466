/* CSS Document */

@media screen and (max-width: 768px) {
	
	.textcontainer-2 {
		width: 95%;
	}
	.textcontainer-8 {
		width: 95%;
	}
	#no-margins1 {
		flex-flow: column-reverse;
	}
	.adf-about-background {
		margin-bottom: 25px;
	}
	
	.ourteam {
		grid-template-columns: 1fr;
		padding: 0px 15px;
	}
	
}




.content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: var(--max-width);
    padding-left: 20px;
    padding-right: 20px;
    margin: 100px auto 0 auto;
    align-items: center;
    gap: 0 20px;
}

.content>div:nth-child(2) {
    order: 2;
}

.content .content__box {
    margin: 20px 0;
}

@media (max-width: 1023px) {
    .content {
    grid-template-columns: 1fr;
    }

    .content>div:nth-child(2) {
    order: unset;
    }

    .content>div:nth-child(3) {
    padding-top: 40px;
    }
}

@media (max-width: 800px) {
    .content {
    margin: 80px auto 0 auto;
    }
}
.language {
    display: flex;
}

/* #region Elements */
.language .language__item {
    display: flex;
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
}

.language .language__item:hover,
.language .language__item:active {
    color: #fff;

}

.language .language__separator {
    display: flex;
    width: 20px;
    display: flex;
    justify-content: center;
    color: white;
}

/* #endregion */

/* #region Modifiers */
.language .language__item.language--is-active {
    font-weight: 700;
    color: white;
}

.box-language {
    display: flex;
    justify-content: center;
}

/* #endregion */
@media (max-width:865px) {
    .box-language .language {
    visibility: hidden;
    display: none;
    }

    .navbar .text__menu .language {
    visibility: visible;
    }
}
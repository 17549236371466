.popup {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(100, 100, 100, 0);
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transform: translate3d(-100%, 0, 0);
    visibility: hidden;
    transition: background-color 0.3s, visibility 0s 0.3s, transform 0s 0.3s;
    will-change: background-color, visibility, transform;
}

/* #region Elements */
.popup .popup__content {
    background: rgb(57, 63, 65);
    background: linear-gradient(135deg, rgba(50, 50, 50, 1) 0%, rgba(2, 2, 2, 1) 100%);
    padding: 30px 34px;
    position: relative;
    max-width: 745px;
    transform: translate3d(0, 50%, 0);
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    will-change: opacity, transform;
}

.popup .popup__title {
    font-size: 15px;
    text-transform: uppercase;
    margin: 0 0 25px 0;
    text-align: center;
}

.popup .popup__info {
    margin: 0 0 15px 0;
    font-size: 15px;
    line-height: 1.5;
}

.popup .popup__table {
    padding: 20px 0 0 0;
}

.popup .popup__table table {
    border: 1px solid var(--color-black);
    border-collapse: collapse;
    margin: 0 auto;
}

.popup .popup__table th {
    background-color: var(--color-black);
    color: #fff;
}

.popup .popup__table th,
.popup .popup__table td {
    font-family: var(--font-normal), sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: 11px;
    padding: 10px;
}

.popup .popup__action-close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 14px;
    height: 14px;
    background-image: url(/img/assets/icons/ico-menu-exit.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
    z-index: 999;
}

/* #endregion */

/* #region Modifiers */
.popup.popup--is-active {
    transform: translate3d(0, 0, 0);
    visibility: visible;
    background-color: rgba(100, 100, 100, 0.5);
    transition: background-color 0.3s, visibility 0s 0s, transform 0s 0s;
}

.popup.popup--is-active .popup__content {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: opacity 0.3s, transform 0.3s;
}

.popup.popup--is-gallery .popup__content {
    max-width: 100%;
    padding: 0;
}

/* #endregion */
@media (max-width: 767px) {
    .popup.popup--is-gallery .popup__content {
    width: 100%;
    height: 100%;
    }
}
body {
	padding-top: 120px;
}



h1, h2, h3, h4, h5, h6 {}

h3 {
}

input:focus,
summary:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}
* {
	-webkit-tap-highlight-color: transparent;
}

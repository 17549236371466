.box-header {}

/* #region Elements */
.box-header .box-header__content {
    padding: 0 20px;
    max-width: var(--max-width);
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
    justify-content: center;
}



.box-header .box-header__highlight {
    padding: 40px 0;
}

.box-header .box-header__highlight>img {
    width: 100%;
}

.box-header .box-header__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
}

.box-header .box-header__title {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 12px;
    color: #070909;
    text-transform: uppercase;
    text-align: center;
}

.box-header .box-header__subtitle {
    font-weight: 700;
    font-size: 34px;
    letter-spacing: 7px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #070909;
    text-transform: uppercase;
}

/* #endregion */

/* #region Modifiers */
/* #endregion */
@media (max-width: 1024px) {
    .box-header {
    /* background: linear-gradient(180deg, rgba(8,76,60,0) 80%, rgba(8,76,60,1) 60%, rgba(8,76,60,1) 100%), url('/img/assets/backgrounds/BG_Home.webp'); */
    background-color: var(--color-primary-light);
    }

    .box-header .box-header__content {
    grid-template-columns: 1fr;
    padding: 0;
    gap: 0;
    }
}

@media(max-width:865px) {
    .box-header {
    margin: 105px 0 0 0;
    }
}
/* CSS Document */


.imageevent {
	position: relative;
  	text-align: center;
}

.eventcentered {
  	position: absolute;
  	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);
}


.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  grid-gap: 20px;
  margin: 0px auto;
}


 
/* (B) THUMBNAILS */
.gallery img {
  width: 100%;
  _cursor: pointer;
}

@media screen and (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(1, auto);
  }
}

